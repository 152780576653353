import { AbstractStore, StoreController } from "@somewear/storage";

import type { KmlImage, MapLayers } from "./layers.model";

class Store<T> extends AbstractStore<T> {
	migrate() {
		// no migration needed
	}

	clean() {
		// clear anything that was previously in local storage
		localStorage.removeItem(this.key);
	}
}

export const MapLayersStore = new Store<MapLayers>(StoreController.STORE_MAP_LAYERS);
export const KmlImagesStore = new Store<KmlImage[]>(StoreController.KML_IMAGES);
