export type KmlOverlayUnitsType = "overlayXY" | "screenXY" | "rotationXY" | "size";

export enum KmlLinkRefreshMode {
	onChange = "onChange",
	onInterval = "onInterval",
	onExpire = "onExpire",
	default = "",
}

export interface IKmlObject {
	type: string;
}

export interface IKmlFeature extends IKmlObject {
	styleUrl?: string;
	name: string;
	open: boolean;
}

export interface IKmlStyleSelector extends IKmlObject {
	id: string;
}

export interface IKmlColorStyle extends IKmlObject {
	color?: string;
}

export interface IKmlStyleMapPair extends IKmlObject {
	key: string;
	styleUrl: string;
}

export interface IKmlStyle extends IKmlStyleSelector {
	styles?: IKmlColorStyle[];
}

export interface IKmlStyleMap extends IKmlObject {
	id: string;
	pairs?: IKmlStyleMapPair[];
}

export interface IKmlIcon {
	href: string;
}

export interface IKmlOverlayUnits {
	type: KmlOverlayUnitsType;
	x: string | number;
	y: string | number;
	xUnits: string | number;
	yUnits: string | number;
}

export interface IKmlOverlay extends IKmlFeature {
	icon: IKmlIcon;
}

export interface IKmlScreenOverlay extends IKmlOverlay {
	overlayXY?: IKmlOverlayUnits;
	screenXY?: IKmlOverlayUnits;
	rotationXY?: IKmlOverlayUnits;
	size?: IKmlOverlayUnits;
}

export interface IKmlLink extends IKmlObject {
	href: string;
	refreshMode?: KmlLinkRefreshMode;
	refreshInterval?: number;
}
export interface IKmlNetworkLink extends IKmlFeature {
	link: IKmlLink;
}
