import type { Dictionary } from "@reduxjs/toolkit";
import type {
	IKmlColorStyle,
	IKmlFeature,
	IKmlIcon,
	IKmlObject,
	IKmlOverlay,
	IKmlOverlayUnits,
	IKmlScreenOverlay,
	IKmlStyle,
	IKmlStyleMap,
	IKmlStyleMapPair,
	IKmlStyleSelector,
	KmlOverlayUnitsType,
} from "@somewear/layers";
import { KmlLinkRefreshMode } from "@somewear/layers";
import { Configuration, Extensions } from "@somewear/model";
import { kml } from "@somewear-labs/togeojson";
import type { JSZipObject } from "jszip";
import JSZip from "jszip";
import { from, Observable, of } from "rxjs";
import { catchError } from "rxjs/internal/operators/catchError";
import { delay, expand, filter, map, mergeMap, switchMap, tap, toArray } from "rxjs/operators";
import type { Element as XmlElement } from "xml-js";
import { xml2js } from "xml-js";

import type { IKmlLink, IKmlNetworkLink } from "./kml.model";
import type { MapLayer } from "./layers.model";
import { mapLayerFactory } from "./mapLayers.slice";

//Need to do this to actually load the extensions
// eslint-disable-next-line
const extensions = Extensions;

export class KmlCoordinates {
	constructor(lat: number, lng: number, alt?: number) {
		this.lat = lat;
		this.lng = lng;
		this.alt = alt;
	}

	lat: number;
	lng: number;
	alt?: number;
}

export abstract class KmlObject implements IKmlObject {
	constructor(type: string) {
		this.type = type;
	}

	type: string;
}

export const applyKmlTextToObject = (children: any[], object: KmlObject): KmlObject => {
	const props: KmlText[] = children.filter((child: any) => child instanceof KmlText);

	const propsObject: any = {};
	props.forEach((prop) => {
		propsObject[prop.key] = prop.value;
	});

	return Object.assign(object, propsObject);
};

export abstract class KmlFeature extends KmlObject implements IKmlFeature {
	protected constructor(type: string, name: string, open: boolean) {
		super(type);
		this.name = name;
		this.open = open;
	}

	styleUrl?: string;
	name: string;
	open: boolean;
}

export abstract class KmlContainer extends KmlFeature {
	features: KmlFeature[] = [];
	networkLinks: KmlNetworkLink[] = [];

	getChildren(): KmlObject[] {
		return [...this.features, ...this.networkLinks];
	}
}

export class KmlDocument extends KmlContainer {
	constructor(name: string, open: boolean) {
		super("Document", name, open);
	}

	styles: KmlStyle[] = [];
	styleMaps: KmlStyleMap[] = [];
	folders: KmlFolder[] = [];
	getChildren(): KmlObject[] {
		return [...super.getChildren(), ...this.styles, ...this.folders, ...this.styleMaps];
	}
}

export class KmlFolder extends KmlContainer {
	constructor(name: string, open: boolean) {
		super("Folder", name, open);
	}
}

export abstract class KmlStyleSelector extends KmlObject implements IKmlStyleSelector {
	constructor(type: string, id: string) {
		super(type);
		this.id = id;
	}

	id: string;
}

export abstract class KmlColorStyle extends KmlObject implements IKmlColorStyle {
	color?: string;
}

export class KmlText {
	constructor(key: string, value: string) {
		this.key = key;
		this.value = value;
	}

	key: string;
	value: string;
}

export class KmlStyle extends KmlStyleSelector implements IKmlStyle {
	constructor(id: string) {
		super("KmlStyle", id);
	}

	styles?: KmlColorStyle[];
}

export class KmlStyleMap extends KmlObject implements IKmlStyleMap {
	constructor(id: string, pairs: KmlStyleMapPair[]) {
		super("KmlStyleMap");
		this.id = id;
		this.pairs = pairs;
	}
	id: string;
	pairs?: KmlStyleMapPair[];
}

export class KmlStyleMapPair extends KmlObject implements IKmlStyleMapPair {
	constructor(key: string, styleUrl: string) {
		super("KmlStyleMapPair");
		this.key = key;
		this.styleUrl = styleUrl;
	}

	key: string;
	styleUrl: string;
}

export class KmlIconStyle extends KmlColorStyle {
	constructor() {
		super("IconStyle");
	}

	scale?: number;
}

export class KmlLabelStyle extends KmlColorStyle {
	constructor() {
		super("LabelStyle");
	}

	scale?: number;
}

export class KmlLineStyle extends KmlColorStyle {
	constructor() {
		super("LineStyle");
	}
	width?: number;
}

export class KmlPolyStyle extends KmlColorStyle {
	constructor() {
		super("PolyStyle");
	}
	fill?: boolean;
	outline?: boolean;
}

export class KmlPlacemark extends KmlFeature {
	constructor(name: string, open: boolean, geometry: KmlGeometry) {
		super("Placemark", name, open);
	}

	geometry?: KmlGeometry;
}

export abstract class KmlGeometry {}

export class KmlLinearRing extends KmlGeometry {
	constructor(coordinates: string) {
		super();

		this.coordinates = coordinates;
	}

	coordinates: string;
}

export class KmlPoint extends KmlGeometry {
	constructor(coordinates: KmlCoordinates) {
		super();

		this.coordinates = coordinates;
	}

	coordinates: KmlCoordinates;
}
export class KmlLineString extends KmlGeometry {}

export class KmlPolygon extends KmlGeometry {
	constructor(outerBoundaryIs: KmlLinearRing) {
		super();
		this.outerBoundaryIs = outerBoundaryIs;
	}

	outerBoundaryIs: KmlLinearRing;
	innerBoundaryIs?: KmlLinearRing;
}

export class KmlLink extends KmlObject implements IKmlLink {
	constructor(href: string, refreshMode?: KmlLinkRefreshMode, refreshInterval?: number) {
		super("Link");
		this.href = href;
		this.refreshMode = refreshMode;
		this.refreshInterval = refreshInterval;
	}
	href: string;
	refreshMode?: KmlLinkRefreshMode;
	refreshInterval?: number;
}

export class KmlNetworkLink extends KmlFeature implements IKmlNetworkLink {
	constructor(link: KmlLink, open: boolean) {
		super("NetworkLink", "", open);
		this.link = link;
	}
	link: KmlLink;
}

export class KmlRoot {
	feature?: KmlFeature;
}

export class KmlIcon implements IKmlIcon {
	constructor(href: string) {
		this.href = href;
	}
	href: string;
}

export class KmlOverlayUnits implements IKmlOverlayUnits {
	constructor(
		type: KmlOverlayUnitsType,
		x: string | number,
		y: string | number,
		xUnits: string | number,
		yUnits: string | number,
	) {
		this.type = type;
		this.x = x;
		this.y = y;
		this.xUnits = xUnits;
		this.yUnits = yUnits;
	}
	type: KmlOverlayUnitsType;
	x: string | number;
	y: string | number;
	xUnits: string | number;
	yUnits: string | number;
}

export class KmlOverlay extends KmlFeature implements IKmlOverlay {
	constructor(type: string, icon: KmlIcon, name: string, open: boolean) {
		super(type, name, open);
		this.icon = icon;
	}
	icon: KmlIcon;
}

export class KmlScreenOverlay extends KmlOverlay implements IKmlScreenOverlay {
	constructor(icon: KmlIcon, name: string, open: boolean) {
		super("ScreenOverlay", icon, name, open);
	}
	overlayXY?: KmlOverlayUnits;
	screenXY?: KmlOverlayUnits;
	rotationXY?: KmlOverlayUnits;
	size?: KmlOverlayUnits;
}

export interface IKmlParserError {
	message: string;
	details: string;
}

export interface IKmlParserResponse {
	data: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
	overlays?: KmlScreenOverlay[];
	styles?: IKmlStyle[];
	styleMaps?: IKmlStyleMap[];
	networkLinks?: KmlNetworkLink[];
	srcLink?: KmlLink;
}

export const fetchNetworkLinkPromise = (link: KmlLink): Promise<IKmlParserResponse> => {
	return new Promise<IKmlParserResponse>((resolve, reject) => {
		const encodedLink = encodeURI(link.href);
		const headers: any = {
			"Content-Type": "application/zip",
		};

		from(
			fetch(`${Configuration.config.somewear.proxyUrl}?url=${encodedLink}`, {
				mode: "cors",
				headers: headers,
			}),
		)
			.pipe(
				mergeMap((r) => {
					return r.blob();
				}),
				mergeMap((blob) => {
					return JSZip.loadAsync(blob);
				}),
				mergeMap((zip) => {
					const files: JSZipObject[] = [];
					zip.forEach((path, entry) => {
						files.push(entry);
					});
					return files;
				}),
				mergeMap((file) => {
					return file.async("text");
				}),
				mergeMap((text) => {
					return of(JSON.parse(text) as IKmlParserResponse);
				}),
				catchError((e) => {
					console.error(e);
					const error: IKmlParserError = {
						message: "Error: failed to upload |FILE_NAME|.",
						details: "We were unable to find Geography data in the file you uploaded.",
					};
					reject(error);
					throw error;
				}),
			)
			.subscribe((json) => {
				const response: IKmlParserResponse = {
					data: json.data,
					overlays: json.overlays,
					styles: json.styles,
					networkLinks: json.networkLinks,
					srcLink: link,
				};
				resolve(response);
			});
	});
};

const getChildren = mergeMap((el: XmlElement) => el.elements as Array<XmlElement>);

const getName = (val: XmlElement): string => {
	let name = "";
	try {
		name = val
			.elements!.find((el) => el.name === "name")!
			.elements!.find((el) => el.type === "text")!.text as string;
	} catch (e) {}
	return name;
};

const getOpen = (val: XmlElement): boolean => {
	let open = true;
	try {
		const openStr = val
			.elements!.find((el) => el.name === "open")!
			.elements!.find((el) => el.type === "text")!.text as string;
		if (openStr === "0") {
			open = false;
		}
	} catch (e) {}
	return open;
};

const getHref = (val: XmlElement): string => {
	let name = "";
	try {
		name = val
			.elements!.find((el) => el.name === "href")!
			.elements!.find((el) => el.type === "text")!.text as string;
	} catch (e) {}
	return name;
};

const getRefereshMode = (val: XmlElement): string => {
	let name = "";
	try {
		name = val
			.elements!.find((el) => el.name === "refreshMode")!
			.elements!.find((el) => el.type === "text")!.text as string;
	} catch (e) {}
	return name;
};

const getRefereshInterval = (val: XmlElement): string => {
	let name = "";
	try {
		name = val
			.elements!.find((el) => el.name === "refreshInterval")!
			.elements!.find((el) => el.type === "text")!.text as string;
	} catch (e) {}
	return name;
};

const getAttributeValue = (val: XmlElement, attributeName: string): string => {
	try {
		const attributeElement = val.elements?.find((el) => el.name === attributeName);
		if (attributeElement?.elements === undefined) return "";
		const attributeValue = attributeElement.elements.find((el) => el.type === "text")?.text;
		return attributeValue as string;
	} catch (e) {}
	return "";
};

const getAllScreenOverlays = (container: KmlDocument | KmlFolder): KmlScreenOverlay[] => {
	return getKmlObjectsRecursive<KmlScreenOverlay>(container, isKmlScreenOverlay).filter(
		isKmlScreenOverlay,
	);
};

const getAllStyles = (container: KmlDocument | KmlFolder): KmlStyle[] => {
	return getKmlObjectsRecursive<KmlStyle>(container, isKmlStyle).filter(isKmlStyle);
};

const getAllStyleMaps = (container: KmlDocument | KmlFolder): KmlStyleMap[] => {
	return getKmlObjectsRecursive<KmlStyleMap>(container, isKmlStyleMap).filter(isKmlStyleMap);
};

const getAllNetworkLinks = (container: KmlDocument | KmlFolder): KmlNetworkLink[] => {
	return getKmlObjectsRecursive<KmlNetworkLink>(container, isKmlNetworkLink).filter(
		isKmlNetworkLink,
	);
};

function isKmlScreenOverlay(obj: KmlObject): obj is KmlScreenOverlay {
	return obj instanceof KmlScreenOverlay;
}

function isKmlStyle(obj: KmlObject): obj is KmlStyle {
	return obj instanceof KmlStyle;
}

function isKmlStyleMap(obj: KmlObject): obj is KmlStyleMap {
	return obj instanceof KmlStyleMap;
}

function isKmlNetworkLink(obj: KmlObject): obj is KmlNetworkLink {
	return obj instanceof KmlNetworkLink;
}

/**
 * Traverses the parsed KML Container tree to find all objects of a given type
 *
 * @param container the current kml container being searched
 * @param typeGuard the function that identifies if the kml object matches the sought type
 */
function getKmlObjectsRecursive<T extends KmlObject>(
	container: KmlContainer | T,
	typeGuard: (x: KmlObject) => x is T,
): Array<KmlDocument | KmlFolder | T> {
	if (container instanceof KmlDocument) {
		// recursively grab the elements for any child folders and concatenate them to the document's elements
		const folderObjects = container.folders.flatMap((folder) =>
			getKmlObjectsRecursive<T>(folder, typeGuard),
		);
		const containerObjects: Array<T> = [];
		container.getChildren().forEach((it) => {
			if (typeGuard(it)) {
				containerObjects.push(it);
			}
		});
		return [...folderObjects, ...containerObjects];
	} else if (container instanceof KmlFolder) {
		// recursively grab the elements for any child folders and documents
		const folders = container
			.getChildren()
			.filter((feature) => feature instanceof KmlFolder)
			.mapNotNull((folder) => folder as KmlFolder);
		const folderObjects = folders.flatMap((folder) =>
			getKmlObjectsRecursive<T>(folder, typeGuard),
		);
		const documents = container
			.getChildren()
			.filter((feature) => feature instanceof KmlDocument)
			.mapNotNull((doc) => doc as KmlDocument);
		const documentObjects = documents.flatMap((document) =>
			getKmlObjectsRecursive<T>(document, typeGuard),
		);

		const containerObjects: Array<T> = [];
		container.getChildren().forEach((it) => {
			if (typeGuard(it)) {
				containerObjects.push(it);
			}
		});
		return [...documentObjects, ...folderObjects, ...containerObjects];
	} else {
		// this is a feature so just return an array with it
		return [container];
	}
}

const getChildrenRecursiveAsArray$ = (val: XmlElement): Observable<any[]> => {
	return getChildrenRecursive$(of(val)).pipe(
		toArray(),
		catchError((e: any) => {
			console.error(e);
			return of([]);
		}),
	);
};

const getChildrenRecursive$ = (elObs: any): any =>
	getChildren(elObs).pipe(
		expand((val: XmlElement): any => {
			if (val.name === undefined) {
				return of();
			} else if (val.name === "Document") {
				return kmlDocumentFactory(val);
			} else if (val.name === "Folder") {
				return kmlFolderFactory(val);
			} else if (val.name === "Style") {
				return kmlStyleFactory(val);
			} else if (val.name === "StyleMap") {
				return kmlStyleMapFactory(val);
			} else if (val.name === "Pair") {
				return kmlStyleMapPairFactory(val);
			} else if (["IconStyle", "PolyStyle", "LineStyle", "LabelStyle"].includes(val.name)) {
				const styleObjectFactoryDict: Dictionary<() => KmlObject> = {
					IconStyle: () => new KmlIconStyle(),
					PolyStyle: () => new KmlPolyStyle(),
					LineStyle: () => new KmlLineStyle(),
					LabelStyle: () => new KmlLabelStyle(),
				};
				const builder = styleObjectFactoryDict[val.name];
				if (builder !== undefined) {
					return new Observable<KmlObject>((observer) => {
						getChildrenRecursiveAsArray$(val).subscribe((next: any) => {
							observer.next(applyKmlTextToObject(next, builder()));
							observer.complete();
						});
					});
				}
				return of();
			} else if (val.name === "ScreenOverlay") {
				return kmlScreenOverlayFactory(val);
			} else if (val.name === "Icon") {
				return kmlIconFactory(val);
			} else if (["overlayXY", "screenXY", "rotationXY", "size"].includes(val.name)) {
				return kmlOverlayUnitsFactory(val);
			} else if (val.name === "NetworkLink") {
				return kmlNetworkLinkFactory(val);
			} else if (val.name === "Polygon") {
				return kmlPolygonFactory(val);
			} else if (val.name === "LinearRing") {
				return kmlLinearRingFactory(val);
			} else if (["LookAt", "Region", "ScreenOverlay"].indexOf(val.name) > -1) {
				// console.log(`The feature (${val.name}) is not supported yet`);
				return of();
			} else if (["MultiGeometry"].indexOf(val.name) > -1 && val.elements) {
				const elements = val.elements.filter((el: XmlElement) => el.type === "element");
				if (elements.length === 0) return of();
				return new Observable((observer) => {
					getChildrenRecursiveAsArray$(val).subscribe((next: Array<any>) => {
						next.filter((element: any) => {
							return element instanceof KmlText;
						}).forEach((element) => {
							observer.next(element);
						});
						observer.complete();
					});
				});
			} else if (["Placemark"].indexOf(val.name) > -1) {
				return kmlPlacemarkFactory(val);
			} else if (["hotSpot"].indexOf(val.name) > -1) {
				console.warn(`The feature (${val.name}) is not supported yet`);
				return of();
			} else if (val.name?.includes("gx:")) {
				// console.log(`The feature (${val.name}) is not supported yet`);
				return of();
			} else if (val?.elements) {
				const attributes = val.elements.filter((el: XmlElement) => el.type === "text");
				const elements = val.elements.filter((el: XmlElement) => el.type === "element");
				if (elements.length) {
					return new Observable((observer) => {
						getChildrenRecursiveAsArray$(val).subscribe((next: Array<any>) => {
							next.filter((element: any) => {
								return element instanceof KmlText;
							}).forEach((element) => {
								observer.next(element);
							});
							observer.complete();
						});
					});
				} else if (attributes.length > 0) {
					if (attributes.length > 1) {
						console.error("We only expected one attribute");
						return of();
					} else {
						const element = new KmlText(val.name, attributes.first().text as string);
						return of(element);
					}
				} else {
					return of();
				}
			}

			return of();
		}),
		filter((it: any) => it.type !== "element"),
		tap((it) => {
			console.log(it);
		}),
	);

/**
 * A factory method that consumes an xml element from a kml and produces the respective KmlScreenOverlay
 *
 * @param val the xml element that will produce KmlScreenOverlay
 */
const kmlScreenOverlayFactory = (val: XmlElement): Observable<KmlScreenOverlay> => {
	return new Observable<KmlScreenOverlay>((observer) => {
		const name = getName(val);
		const open = getOpen(val);
		getChildrenRecursiveAsArray$(val).subscribe((children: any[]) => {
			const icon = children.find((child) => child instanceof KmlIcon);
			if (icon !== undefined) {
				const overlay = new KmlScreenOverlay(icon, name, open);

				children
					.filter((it) => it instanceof KmlOverlayUnits)
					.forEach((it: KmlOverlayUnits) => {
						overlay[it.type] = it;
					});

				observer.next(overlay);
			}
			observer.complete();
		});
	});
};

const kmlIconFactory = (val: XmlElement): Observable<KmlIcon> => {
	return new Observable<KmlIcon>((observer) => {
		getChildrenRecursiveAsArray$(val).subscribe((children: any[]) => {
			children.forEach((child) => {
				if (child instanceof KmlText && child.key === "href") {
					observer.next(new KmlIcon(child.value));
				}
			});

			observer.complete();
		});
	});
};

const kmlPolygonFactory = (val: XmlElement): Observable<KmlPolygon> => {
	return new Observable<KmlPolygon>((observer) => {
		getChildrenRecursiveAsArray$(val).subscribe((children: any[]) => {
			children.forEach((child) => {
				if (child instanceof KmlLinearRing) {
					observer.next(new KmlPolygon(child));
				}
			});

			observer.complete();
		});
	});
};

const kmlLinearRingFactory = (val: XmlElement): Observable<KmlLinearRing> => {
	return new Observable<KmlLinearRing>((observer) => {
		getChildrenRecursiveAsArray$(val).subscribe((children: any[]) => {
			children.forEach((child) => {
				if (child instanceof KmlText && child.key === "coordinates") {
					observer.next(new KmlLinearRing(child.value));
				}
			});

			observer.complete();
		});
	});
};

/**
 * A factory method that consumes an xml element from a kml and produces the respective KmlDocument
 *
 * @param val the xml element that will produce KmlDocument
 */
const kmlDocumentFactory = (val: XmlElement): Observable<KmlDocument> => {
	return new Observable<KmlDocument>((observer) => {
		getChildrenRecursiveAsArray$(val).subscribe((next: Array<any>) => {
			const name = getName(val);
			const open = getOpen(val);
			const document = new KmlDocument(name, open);
			applyKmlTextToObject(next, document);
			next.forEach((entity) => {
				if (entity instanceof KmlFolder) {
					document.folders.push(entity);
				} else if (entity instanceof KmlStyle) {
					document.styles.push(entity);
				} else if (entity instanceof KmlStyleMap) {
					document.styleMaps.push(entity);
				} else if (entity instanceof KmlScreenOverlay) {
					document.features.push(entity);
				} else if (entity instanceof KmlNetworkLink) {
					document.networkLinks.push(entity);
				}
			});
			observer.next(document);
			observer.complete();
		});
	});
};

const kmlFolderFactory = (val: XmlElement): Observable<KmlFolder> => {
	return new Observable<KmlFolder>((observer) => {
		getChildrenRecursiveAsArray$(val).subscribe((next: Array<any>) => {
			const name = getName(val);
			const open = getOpen(val);
			const folder = new KmlFolder(name, open);
			applyKmlTextToObject(next, folder);
			next.forEach((entity) => {
				if (entity instanceof KmlDocument) {
					folder.features.push(entity);
				} else if (entity instanceof KmlFolder) {
					folder.features.push(entity);
				} else if (entity instanceof KmlScreenOverlay) {
					folder.features.push(entity);
				} else if (entity instanceof KmlNetworkLink) {
					folder.networkLinks.push(entity);
				}
			});
			observer.next(folder);
			observer.complete();
		});
	});
};

const kmlPlacemarkFactory = (val: XmlElement): Observable<KmlPlacemark> => {
	return new Observable<KmlPlacemark>((observer) => {
		getChildrenRecursiveAsArray$(val).subscribe((children: Array<any>) => {
			const name = getName(val);
			const open = getOpen(val);
			const geometry = children.find((it) => it instanceof KmlGeometry);
			if (geometry !== undefined) {
				const placemark = new KmlPlacemark(name, open, geometry);
				observer.next(placemark);
			}
			observer.complete();
		});
	});
};

const kmlStyleFactory = (val: XmlElement): Observable<KmlStyle> => {
	return new Observable<KmlStyle>((observer) => {
		getChildrenRecursiveAsArray$(val).subscribe((next: any) => {
			const colorStyles = next.filter((child: any) => child instanceof KmlColorStyle);

			if (colorStyles.length === 0) {
				const errMsg = "unable to find color styles in kml";
				// Sentry.captureException(errMsg);
				console.error(errMsg);
				// observer.error("unable to find color styles in kml");
				observer.complete();
				// throw Error("unable to find color styles in kml");
			} else {
				const style = new KmlStyle(val.attributes?.id as string);
				style.styles = colorStyles;

				observer.next(style);
				observer.complete();
			}
		});
	});
};

const kmlStyleMapFactory = (val: XmlElement): Observable<KmlStyleMap> => {
	return new Observable<KmlStyleMap>((observer) => {
		const id = val.attributes?.id ?? "";
		getChildrenRecursiveAsArray$(val).subscribe((next: any) => {
			const pairs = next.filter((child: any) => child instanceof KmlStyleMapPair);
			const styleMap = new KmlStyleMap("#" + id, pairs);

			observer.next(styleMap);
			observer.complete();
		});
	});
};

const kmlStyleMapPairFactory = (val: XmlElement): Observable<KmlStyleMapPair> => {
	return new Observable<KmlStyleMapPair>((observer) => {
		const key = getAttributeValue(val, "key");
		const styleUrl = getAttributeValue(val, "styleUrl");
		const styleMapPair = new KmlStyleMapPair(key, styleUrl);

		observer.next(styleMapPair);
		observer.complete();
	});
};

/**
 * A factory method that consumes an xml element from a kml and produces the respective KmlOverlayUnits
 *
 * @param val the xml element that will produce KmlOverlayUnits
 */
const kmlOverlayUnitsFactory = (val: XmlElement): Observable<KmlOverlayUnits> => {
	return new Observable<KmlOverlayUnits>((observer) => {
		if (val.attributes !== undefined) {
			const x = val.attributes["x"];
			const y = val.attributes["y"];
			const xUnits = val.attributes["xunits"];
			const yUnits = val.attributes["yunits"];
			if (
				x !== undefined &&
				y !== undefined &&
				xUnits !== undefined &&
				yUnits !== undefined
			) {
				const name: KmlOverlayUnitsType = val.name as KmlOverlayUnitsType;
				observer.next(new KmlOverlayUnits(name, x, y, xUnits, yUnits));
			}
		}
		observer.complete();
	});
};

const kmlNetworkLinkFactory = (val: XmlElement): Observable<KmlNetworkLink> => {
	return new Observable<KmlNetworkLink>((observer) => {
		const linkElement = val.elements?.find((element) => element.name === "Link");
		if (linkElement !== undefined) {
			const href = getHref(linkElement);
			const refreshMode = getRefereshMode(linkElement);
			const refreshInterval = getRefereshInterval(linkElement);
			const link = new KmlLink(
				href,
				refreshMode as KmlLinkRefreshMode,
				parseFloat(refreshInterval),
			);
			const open = getOpen(val);
			observer.next(new KmlNetworkLink(link, open));
		}
		observer.complete();
	});
};

export function loadKml(kmlText: string): Promise<IKmlParserResponse[]> {
	console.log("loadKml");

	return new Promise<IKmlParserResponse[]>((resolve, reject) => {
		console.log("loadKml ---- start");

		const xml = new DOMParser().parseFromString(kmlText, "text/xml");
		const parsedXml = kml(xml);
		const features = parsedXml as GeoJSON.FeatureCollection<GeoJSON.Geometry>;
		// console.log(features);

		console.log("loadKml ---- loaded");

		try {
			// hack to fix space issues with styleUrl from kml
			// https://github.com/mapbox/togeojson/issues/52
			features.features.forEach((feature: any) => {
				for (const k in feature.properties) {
					if (typeof feature.properties[k] === "string") {
						const property: string = feature.properties[k] as string;

						if (k === "styleUrl") {
							feature.properties[k] = property.replaceAll(" ", "");
						} else {
							feature.properties[k] = property.trim();
						}
					}
				}
			});
		} catch (e) {
			console.error("unable to fix space issues with styleUrl");
			console.error(e);
		}

		console.log("loadKml ---- converting xml to js");

		const kmlAsJs = xml2js(kmlText);

		console.log("loadKml ---- creating observables");

		const kmlObs = from(kmlAsJs.elements).pipe(
			filter((item: any) => item.type === "element" && item.name === "kml"),
		);

		console.log("loadKml ---- getChildren");

		const kmlParser$ = new Observable<KmlContainer[]>((observer) => {
			getChildrenRecursive$(kmlObs)
				.pipe(
					filter((item: any) => {
						return item instanceof KmlDocument || item instanceof KmlFolder;
					}),
					toArray(),
				)
				.subscribe((next: KmlContainer[]) => {
					console.log(next);
					observer.next(next);
					observer.complete();
				})
				.error((e: any) => {
					console.error(e);
				});
		});

		console.log("kmlParser ---- subscribe");

		kmlParser$.subscribe((result) => {
			console.log("kmlParser ---- parsed");

			const screenOverlays = result.flatMap((it) => getAllScreenOverlays(it));
			const styles = result.flatMap((it) => getAllStyles(it));
			const styleMaps = result.flatMap((it) => getAllStyleMaps(it));
			const networkLinks = result.flatMap((it) => getAllNetworkLinks(it));

			if (features.features.length > 0) {
				resolve([
					{
						data: features,
						overlays: screenOverlays,
						styles: styles,
						styleMaps: styleMaps,
					},
				]);
			} else {
				const hasLinks = networkLinks.isNotEmpty();

				if (hasLinks) {
					const results = networkLinks.map((networkLink) => {
						const response: IKmlParserResponse = {
							data: features,
							overlays: screenOverlays,
							styles: styles,
							srcLink: networkLink.link,
						};
						return response;
					});

					resolve(results);

					/*Promise.all(
						networkLinks.map((networkLink) => fetchNetworkLinkPromise(networkLink.link))
					)
						.then((r) => {
							resolve(r);
						})
						.catch((e) => {
							reject(e);
						});*/
				} else {
					const error: IKmlParserError = {
						message: "Error: failed to upload |FILE_NAME|.",
						details: "We were unable to find Geography data in the file you uploaded.",
					};
					reject(error);
				}
			}
		});
	});
}

export const fetchNetworkLink$ = (layer: MapLayer): Observable<MapLayer> =>
	of(layer).pipe(
		expand((layer, index) => {
			console.log(layer);
			// this shouldn't happen, but in case it does, close the expand
			if (layer.srcLink === undefined) return of();

			const refreshOnInterval = layer.srcLink.refreshMode === KmlLinkRefreshMode.onInterval;
			const refreshInterval = layer.srcLink.refreshInterval ?? 3600;
			// we've already fetched this link, and it doesn't have a refresh interval, no need to fetch it again
			if (!refreshOnInterval && index > 0) return of();

			console.log("the layer will auto refresh");

			return of(layer.srcLink).pipe(
				switchMap((link) => {
					return of(link).pipe(
						// for the first time, request immediately. for further request wait for refreshInterval
						index === 0 ? tap() : delay(refreshInterval * 1000),
						mergeMap((link) => from(fetchNetworkLinkPromise(link))),
						map((r) => {
							return mapLayerFactory(layer, r);
						}),
					);
				}),
			);
		}),
	);
